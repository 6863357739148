<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a-select placeholder="Status Metode Pembayaran"></a-select>
       <a-input-search class="m-2" v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button type="primary" title="cari" @click="search">
          <i class="fa fa-search" aria-hidden="true"></i>
        </a-button>

        <a-button
          class="ml-2"
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        </a-button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MFilter',
}
</script>

<style scoped>
.row {
  /* border: 1px dotted gray; */
}

/* .row .ant-select,
.row .ant-input {
  width: 50%;
  margin-top: 5px;
} */
</style>
